/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ index, follow, description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  // https://stackoverflow.com/questions/66858464/prevent-gatsby-page-at-a-specific-domain-path-from-appearing-in-google-search
  return (
    <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <meta name={`robots`} content={`${index ? `index`:`noindex`},${follow ? `follow`:`nofollow`}`}/>
      <meta name={`description`} content={metaDescription}/>
      <meta name={`og:title`} content={title}/>
      <meta name={`og:description`} content={metaDescription}/>
      <meta name={`og:type`} content={`website`}/>
      <meta name={`twitter:card`} content={`summary`}/>
      <meta name={`twitter:creator`} content={site.siteMetadata.author}/>
      <meta name={`twitter:title`} content={title}/>
      <meta name={`twitter:description`} content={metaDescription}/>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
