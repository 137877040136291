import { Link } from 'gatsby';
import * as React from 'react';

const Header = () => {
  const [isDisplayed, setIsDisplayed] = React.useState(
    getInitialDisplayOptions(null)
  );
  const [width, setWidth] = React.useState(null);
  let header;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsDisplayed(getInitialDisplayOptions(window.innerWidth));
      setWidth(window.innerWidth);

      const handleResize = () => {
        setIsDisplayed(getInitialDisplayOptions(window.innerWidth));
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  header = (
    <ul className="navbar-ul">
      {(!isDisplayed || width >= 600) && (
        <Link className="header-link-home" to="/">
          amb.horse
        </Link>
      )}
      <div className="navbar-right">
        {isDisplayed || width >= 600 ? (
          <>
            <Link className="navbar-item" to="/bookshelf">
              Bookshelf
            </Link>
            <Link className="navbar-item" to="/writing">
              Writing
            </Link>
            <Link className="navbar-item" to="/projects">
              Projects
            </Link>
            <Link className="navbar-item" to="/now">
              Now
            </Link>
          </>
        ) : (
          <div
            className="navbar-item expanding-item"
            onClick={() => setIsDisplayed(true)}
          >
            More
          </div>
        )}
      </div>
    </ul>
  );

  return <header className="global-header">{header}</header>;
};

export default Header;

function getInitialDisplayOptions(width) {
  if (width < 600) {
    return false;
  }
  return true;
}
